<template>
  <div class="LocalesTool mr-3">
    <CButtonGroup>
      <CButton size="sm" :color="(NowChooseLanguage === 'default' ? 'info' : 'light')"  @click="ChangeLocale('default')">{{ $t('Locale.Default') }}</CButton>
      <CButton size="sm" v-for="(value, key) in $store.state.user.permission.Locales" :key="key" class="d-flex align-items-center" :color="(NowChooseLanguage === value ? 'info' : 'light')"  @click="ChangeLocale(value)">
        <img :src="`https://storage.ksong.tw/Locales/${value}.png`" class="mr-2" :alt="value"> {{ $t('Locale.' + value) }}
      </CButton>
    </CButtonGroup>
  </div>
</template>

<script>
export default {
  props: {
    NowChooseLanguage: {
      type: String,
      default: 'default'
    }
  },
  name: "WidgetsLocales",
  methods: {
    ChangeLocale (locale) {
      this.$emit('update:NowChooseLanguage', locale)
    }
  }
}
</script>
